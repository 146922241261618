import React from "react";
import {Link} from "gatsby";

import background from "src/images/community/card-grants-program-overview.jpg";
import grants from "src/images/community/card-grants-faqs-2.jpg";
import opportunities from "src/images/community/card-grants-opportunities-2.jpg";
import projects from "src/images/community/card-funded-projects-2.png";

/**
 *  Cards on Community Grants Program page
 */
class CommunityGrantsCards extends React.Component {
  render() {
    return (
      <section className="cards cards--square-4 cards--community--grants gutter">
        <h2 className="heading heading--med line">Explore Community Grants Resources</h2>
        <p className="intro">The Community Grants Program invests in community projects that reduce Port environmental
          impacts.</p>

        <ul className="card card--square-4">
          <li>
            <Link to="/community/community-grants-program/#program-overview">
              <div className="card__photo-txt">
                <img src={background} alt=""/>
                <p className="card__title">Program Overview</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/community-grants-program/#community-grant-opportunities">
              <div className="card__photo-txt">
                <img src={opportunities} alt=""/>
                <p className="card__title">Grant Opportunities</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/community-grants-program/#previously-funded-projects">
              <div className="card__photo-txt">
                <img src={projects} alt=""/>
                <p className="card__title">Previously Funded Projects</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/community-grants-program/#faqs">
              <div className="card__photo-txt">
                <img src={grants} alt=""/>
                <p className="card__title">Grants FAQ</p>
              </div>
            </Link>
          </li>
        </ul>
      </section>
    );
  }
}

export default CommunityGrantsCards;
